import { useEffect, useState, useCallback } from "react";
import { Amplify } from 'aws-amplify';
import { CognitoService } from "@root/services/cognitoService";
import { CONNECTION_STATE_CHANGE, ConnectionState, generateClient } from "aws-amplify/api";
import { Hub } from 'aws-amplify/utils';
import { incomingCallDataStore } from "@root/framework/stores/calls-store";
import { incomingCallSubscription } from "@root/graphql/subscriptions";
import { Observable } from 'rxjs';
import { userRootDataStore } from "@root/framework/stores/user-store";
import { useStore } from "@nanostores/react";
import type { CallStatusTypes } from "@root/framework/enums/call-status";

interface HubPayload {
    event: string;
    data: {
        connectionState: ConnectionState;
    };
}

interface MessageData {
    type: CallStatusTypes;
    data: {
        incomingCallData?: any;
        lostCallData?: any;
    };
}

const AppsyncListener = () => {
    const $userRootDataStore = useStore(userRootDataStore);
    const [apiStatus, setApiStatus] = useState<ConnectionState>(ConnectionState.Disconnected);
    const [accessToken, setAccessToken] = useState<string | undefined>(undefined);

    Amplify.configure({
        ...Amplify.getConfig(),
        API: {
            GraphQL: {
                endpoint: import.meta.env.PUBLIC_GRAPHQL_API_ENDPOINT,
                region: 'sa-east-1',
                defaultAuthMode: 'lambda'
            }
        }
    });

    useEffect(() => {
        Hub.listen('api', (data: { payload: HubPayload }) => {
            const { payload } = data;
            if (payload.event === CONNECTION_STATE_CHANGE) {
                const connectionState = payload.data.connectionState;
                setApiStatus(connectionState);
            }
        });
    }, [])

    useEffect(() => {
        if (!$userRootDataStore?.id) return;
        console.log("API Status:", apiStatus);
        if (apiStatus === ConnectionState.Disconnected) {
            const cognitoService = new CognitoService();
            cognitoService.getAccessToken().then((accessToken) => {
                setAccessToken(accessToken?.toString());
                // TODO: implement logic if no token
                // maybe show alert for refresh page
            });
        }

    }, [$userRootDataStore, apiStatus]);

    useEffect(() => {
        if (!accessToken) return;

        const userCalling = generateClient({
            authMode: 'lambda',
            authToken: accessToken
        }).graphql({
            query: incomingCallSubscription,
            variables: {
                name: `v2/users/${$userRootDataStore?.id}`
            },
        }) as Observable<any>;

        const subscription = userCalling.subscribe({
            next: (message: { data: { subscribe: { data: string } } }) => {
                const eventData: MessageData = JSON.parse(message.data.subscribe.data);
                switch (eventData.type) {
                    case "INCOMING_CALL":
                        console.log(eventData);
                        setCallStore(eventData.type, eventData.data.incomingCallData);
                        break;
                    case "LOST_CALL":
                        setCallStore(eventData.type, eventData.data.lostCallData);
                        break;
                }
            },
            error: (error: Error) => {
                // TODO: show alert for error
                console.error(error);
            }
        });

        return () => subscription.unsubscribe();

    }, [accessToken]);

    const setCallStore = useCallback((status: string, callData: any) => {
        incomingCallDataStore.set({
            ...callData,
            callStatus: status,
        })
    }, []);
}

export default AppsyncListener